class changePasswordModalComponent extends Component {

    static name() {
        return "changePasswordModalComponent";
    }

    static componentName() {
        return "changePasswordModalComponent";
    }

    data(){
        return {
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            errorAlertDataSession: null,
            errorAlert:null
        };
    }

    getProps() {
        return  ['closeCarModal'];
    }

    mounted(){
        return async function () {
            $(this.$refs['changePasswordAction']).modal({
                dismissible: true,
                complete: this.close,
            });
        };
    }

    getMethods() {
        return {
            close:this.close,
            changePassword:this.changePassword
        };
    }

    async changePassword() {
        this.errorAlert = null;
        if (!this.oldPassword)
            this.errorAlert = 'Please type your actual password';
        else if (!this.newPassword)
            this.errorAlert = 'Please type a new password';
        else if (!this.confirmPassword)
            this.errorAlert = 'Please confirm the new password';
        else if (this.newPassword !== this.confirmPassword)
            this.errorAlert = "The Passwords does'nt match";

        if (!this.errorAlert) {
            let data = {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
            };

            await axios.post('/ecommerce/api/changePassword', data)
                .then(response => {
                    this.response = response;
                })

            if (!this.response.data.success) {
                this.errorAlert = this.response.data.errors;
                this.oldPassword = null;
                this.newPassword = null;
                this.confirmPassword = null;
            } else {
                this.oldPassword = null;
                this.newPassword = null;
                this.confirmPassword = null;
                this.$store.dispatch('showNotificacion', {
                    title: 'Cambio de Password',
                    message: 'Password cambiado satisfactoriamente',
                    type: 'success'
                });
                this.close();
            }
        }
    }

    close() {
        this.oldPassword = null;
        this.newPassword = null;
        this.confirmPassword = null;
        $("#changePasswordAction").modal('close');
    }

    getTemplate() {
        return `<div ref="changePasswordAction" id="changePasswordAction" class="modal" >
                  <div class="modal-content">
                      <h5 class="center-align">{{tr('Login')}}</h5>
                       <div id="ChangePassword" >
                            <div class="col-sm-12">
                                <div ref='errorChangePassword' class="card-panel  orange darken-1 white-text" role="alert" v-if="errorAlert">
                                    <strong>{{tr(errorAlert)}}!</strong>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="errorAlert = ''" style="float:right;background: transparent;border: none;">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div class="content-block">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="password_old" class="form-label">{{tr('Old Password')}}</label>
                                            <input id="password_old" type="password" class="form-control" v-model="oldPassword" autocomplete="new-password">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="password_1" class="form-label">{{tr('New Password')}}</label>
                                            <input id="password_1" type="password" class="form-control" v-model="newPassword" autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="password_2" class="form-label">{{tr('Retype New Password')}}</label>
                                            <input id="password_2" type="password" class="form-control" v-model="confirmPassword" autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                  </div>
                  <div class="modal-footer">
                      <button class="waves-effect waves-green btn " @click.prevent="changePassword">{{tr('Change Password')}}</button>
                      <button class="waves-effect waves-green btn-flat  " @click.prevent="close">{{tr('Cancel')}}</button>
                  </div>
              </div>`;
    }
}

changePasswordModalComponent.registerComponent();